import type {MDXProviderComponents} from '@mdx-js/react'
import type {PageProps} from 'gatsby'
import {graphql} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import React from 'react'

import {Link} from '@pleo-io/telescope'

import {Layout} from '@/components/layout'
import type {GetChangelogQuery} from '@/graphql-types'

import {Intro} from '../docs'
import {TelescopeMDXComponents} from '../layout/mdx-components'

const components: MDXProviderComponents = {
    ...TelescopeMDXComponents,
    // Replace h1s to h2s as the changelog.md file has each versions as h1s
    h1: TelescopeMDXComponents['h2']
}

const MDXRendererLayout = (props: {data: GetChangelogQuery} & PageProps) => {
    const headings = props.data.mdx.headings.map((heading) => {
        return {
            depth: heading.depth,
            label: heading.value.split('(')[0].trim(),
            value: heading.value
        }
    })

    return (
        <Layout
            {...props}
            pageContext={props.pageContext}
            components={components}
            data={{
                ...props.data,
                mdx: {
                    ...props.data.mdx,
                    headings
                }
            }}
        >
            <Intro>
                On this page you'll find a chronologically ordered list of notable changes for each
                version of Telescope. We follow{' '}
                <Link
                    inherit
                    href="https://semver.org/#introduction"
                    target="_blank"
                    rel="noreferrer"
                >
                    semantic versioning
                </Link>
                .
            </Intro>
            <MDXRenderer headings={headings}>{props.data.mdx.body}</MDXRenderer>
        </Layout>
    )
}

export default MDXRendererLayout

export const query = graphql`
    query getChangelog($id: String!) {
        mdx(id: {eq: $id}) {
            body
            headings(depth: h1) {
                depth
                value
            }
        }
    }
`
